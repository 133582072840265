import { useAuth0 } from '@auth0/auth0-react'
import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect } from 'react'

import { libraryUrl } from '../../../lib/library'
import { SocialMediaResource } from './SocialMediaResource'

export type SocialMediaResourceRouteProps = RouteComponentProps<{ slug: string }>

export const SocialMediaResourceRoute: React.FC<SocialMediaResourceRouteProps> = ({ slug }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!slug || isAuthenticated || isLoading) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${libraryUrl}/social-media-resource/${slug}`,
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect, slug])

  if (!slug || isLoading) {
    return null
  }

  return <SocialMediaResource slug={slug} />
}
