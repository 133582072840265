import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import { Grid } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import MagnifyingGlassSvg from '../../../../img/magnifying-glass.inline.svg'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

export const MagnifyingGlass = styled(MagnifyingGlassSvg)`
  color: ${({ theme }) => theme.colors.brand.orange};
  display: inline-block;
  height: 2em;
  vertical-align: -0.7em;
`

export const TopNav = styled.nav`
  padding: 0 20px;
  margin: 24px 0;
  a {
    color: ${({ theme }) => theme.colors.component.link};
  }
  @media (min-width: 600px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`

export const PaddedGrid = styled(Grid)`
  && {
    padding: 0 32px;
    margin-bottom: 64px;
    @media (min-width: 600px) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }
`

export const Title = styled.h2`
  margin-bottom: 0;
`

export const DownloadImageButton = styled(PrimaryButton)`
  font-size: 15px;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  display: block;
  max-width: 400px;
  margin: 0 auto 32px;
  @media (min-width: 960px) {
    margin: 0;
  }
`

export const Guidelines = styled.aside`
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 960px) {
    margin: 0;
  }
`

export const GuidelinesTitle = styled.h4`
  margin-bottom: 16px;
`

export const ImageWrapper = styled(GatsbyImage)`
  && {
    display: block;
    margin: 0 auto 24px;
    max-width: 400px;
    @media (min-width: 960px) {
      margin: 0 0 24px;
    }
  }
`

export const GuidelinesList = styled.ul`
  max-width: 30em;
`

export const GuidelinesListEntry = styled.li`
  margin-bottom: 0.5rem;
`
