import { Grid } from '@material-ui/core'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { SocialMediaResourceResourceDocument } from '../../../../graphql/api'
import { SanityImage } from '../../../../graphql/gatsby'
import { useApiQuery } from '../../../../hooks/useApiQuery'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { buildResourceAssetUrl, libraryUrl } from '../../../../lib/library'
import { getGatsbyImageDataForAsset } from '../../../../utils/sanity/image'
import ContactSupportMessage from '../../../ContactSupportMessage'
import FullPageError from '../../../FullPageError'
import Link from '../../../global/Link'
import Spinner from '../../../Spinner'
import * as Styled from './styled'

export interface SocialMediaResourceProps {
  slug: string
}

export const SocialMediaResource: FC<SocialMediaResourceProps> = ({ slug }) => {
  const { called, loading, data, error } = useApiQuery(SocialMediaResourceResourceDocument, {
    variables: { slug },
    skipWithoutToken: true,
  })
  useLogAndCaptureError(error)

  const socialMediaResource = data?.socialMediaResourceResource
  const imageData = socialMediaResource?.asset
    ? getGatsbyImageDataForAsset(socialMediaResource.asset as SanityImage, {
        width: 400,
      })
    : undefined

  if (!called || loading) {
    return (
      <div>
        <Helmet title="Loading Social Media Resource" />
        <Spinner loading />
      </div>
    )
  }

  if (error) {
    return (
      <FullPageError>
        <Helmet title="Error" />
        An error occurred retrieving data, and we have been notified.
        <small>
          <ContactSupportMessage />
        </small>
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  if (!socialMediaResource) {
    return (
      <FullPageError>
        <Helmet title="CASI Session not found" />
        <Styled.MagnifyingGlass /> Social Media Resource Not Found
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  return (
    <Styled.Container>
      <Helmet title={socialMediaResource?.title || 'Social Media Resource'} />
      <Styled.TopNav>
        <Link to={libraryUrl}>Go back to Library</Link>
      </Styled.TopNav>
      <Styled.PaddedGrid container spacing={10}>
        {socialMediaResource?.title && (
          <Grid component="header" item xs={12}>
            <Styled.Title>{socialMediaResource.title}</Styled.Title>
          </Grid>
        )}
        <Grid component="main" item xs={12} md={6}>
          {imageData && <Styled.ImageWrapper image={imageData} loading="eager" alt="" />}
          <Styled.DownloadImageButton
            as={'a'}
            href={buildResourceAssetUrl('social-media-resource', slug)}
            download
          >
            Download Hi-Res Image
          </Styled.DownloadImageButton>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Styled.Guidelines>
            <Styled.GuidelinesTitle>Social Media Guidelines:</Styled.GuidelinesTitle>
            <Styled.GuidelinesList>
              <Styled.GuidelinesListEntry>
                All social media posts that display Designs for Health products require the hashtag
                #designsforhealth
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                No media buys, banner ads, online behavioral advertising, affiliate marketing
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>No sponsored ads/posts</Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                No price promotion reference or inference, including without limitation discounts,
                free shipping, giveaways, rebates, sweepstakes, special call-in pricing, coupons,
                promo codes, price-matching policies, etc.
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                No payments for lead generation, click-throughs, or referral fees
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                No off-label claims - only claims on DFH label or in patient-directed materials
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                No “where to purchase,” “buy here” or “we sell DFH” Calls to Action
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                Practitioners are free to repost Designs for Health Social Media posts on their
                personal social media platforms
              </Styled.GuidelinesListEntry>
              <Styled.GuidelinesListEntry>
                Practitioners are free to use the Social Media Assets under Business Building
                Resources in the Education section of{' '}
                <a href="https://www.designsforhealth.com" target="_blank" rel="noreferrer">
                  https://www.designsforhealth.com
                </a>
              </Styled.GuidelinesListEntry>
            </Styled.GuidelinesList>
          </Styled.Guidelines>
        </Grid>
      </Styled.PaddedGrid>
    </Styled.Container>
  )
}
