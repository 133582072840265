import { useAuth0 } from '@auth0/auth0-react'
import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import useUserCategory from '../../../hooks/useUserCategory'
import Head from '../../../layouts/Head'
import { getRouterBasePath, useDispensaryContext } from '../../../lib/dispensaries'
import { isUserCategoryProfessional, libraryUrl } from '../../../lib/library'
import { usePageUrl } from '../../../lib/pages'
import { Error } from '../../Error'
import Layout from '../../global/Layout'
import Spinner from '../../Spinner'
import { SocialMediaResourceRootRoute } from './SocialMediaResourceRootRoute'
import { SocialMediaResourceRoute } from './SocialMediaResourceRoute'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
`

export const SocialMediaResourcePage: FC = () => {
  const { navigate } = usePageUrl()
  const { isAuthenticated, isLoading, error } = useAuth0()
  const { userCategory } = useUserCategory()
  const isProfessional = isUserCategoryProfessional(userCategory)

  useEffect(() => {
    if (!isLoading && isAuthenticated && !isProfessional) {
      navigate(libraryUrl)
    }
  }, [isLoading, isAuthenticated, isProfessional, navigate])

  return (
    <>
      <Head title="Social Media Resource" />
      <GlobalStyle includeTypography />
      <Layout>
        <Container>
          {(isLoading || !isAuthenticated) && (
            <>
              <Helmet title="Loading" />
              <Spinner loading />
            </>
          )}
          {error && (
            <>
              <Helmet title="Error" />
              <Error message={error.message} />
            </>
          )}
          <Router
            basepath={getRouterBasePath(
              `${libraryUrl}/social-media-resource`,
              !!useDispensaryContext(),
            )}
          >
            <SocialMediaResourceRootRoute path="/" />
            <SocialMediaResourceRoute path="/:slug" />
          </Router>
        </Container>
      </Layout>
    </>
  )
}
